import { type EventDto, EventType } from 'core'
import { defineAsyncComponent } from 'vue'

interface EventConfig {
  notify: (event: EventDto) => string
  widget: ReturnType<typeof defineAsyncComponent>
}

export const eventConfigs: Record<EventType, EventConfig> = {
  [EventType.DEVICE_GEOLINK_RELOCATION]: {
    notify: (event: EventDto<EventType.DEVICE_GEOLINK_RELOCATION>) => {
      return `Системою геолінк виявлено переміщення пристрою <span class="font-semibold">№${event.context.deviceId}</span>`
    },
    widget: defineAsyncComponent(
      () => import('./ui/pages/EventList/Widget/DeviceGeolinkRelocation.vue'),
    ),
  },
  [EventType.DEVICE_TELEMETRY_BMS_FAULT]: {
    notify: (event: EventDto<EventType.DEVICE_TELEMETRY_BMS_FAULT>) => {
      return `Відсутність зв'язку із системою керування батареями за пристроєм <span class="font-semibold">№${event.context.deviceId}</span>`
    },
    widget: defineAsyncComponent(
      () => import('./ui/pages/EventList/Widget/DeviceTelemetryBmsFault.vue'),
    ),
  },
  [EventType.DEVICE_TELEMETRY_INVERTER_FAULT]: {
    notify: (event: EventDto<EventType.DEVICE_TELEMETRY_INVERTER_FAULT>) => {
      return `Відсутність зв'язку з інвертером за пристроєм <span class="font-semibold">№${event.context.deviceId}</span>`
    },
    widget: defineAsyncComponent(
      () =>
        import('./ui/pages/EventList/Widget/DeviceTelemetryInverterFault.vue'),
    ),
  },
}
